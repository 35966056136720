import { captureException } from "@sentry/vue";
import { useHead } from "@unhead/vue";
import { computed, ref, watchEffect } from "vue";

import { useTranslationsQuery } from "@/api/queries/use-translations-query";
import en from "@/locales/en.json";

import { useTranslate } from "./use-translate";

function getKey(locale: string): string {
  return `translations-${locale}`;
}

function getCachedTranslation(locale: string) {
  try {
    return JSON.parse(localStorage.getItem(getKey(locale)) || "{}");
  } catch {
    return {};
  }
}

function setCachedTranslation(locale: string, translations: typeof en) {
  try {
    localStorage.setItem(getKey(locale), JSON.stringify(translations));
  } catch (error) {
    captureException(error);
  }
}

/**
 * Downloads remote translations and caches them in the local storage.
 * If a request fails it will use the cached translations.
 */
export function useRemoteTranslations() {
  const { locale, setLocaleMessage } = useTranslate();
  const translations = ref(getCachedTranslation(locale.value));

  useHead({
    htmlAttrs: {
      lang: locale.value,
    },
  });

  watchEffect(() => {
    setLocaleMessage(locale.value, translations.value);
  });

  const { data, isLoading, isError, error } = useTranslationsQuery();

  watchEffect(() => {
    // Translations loaded
    if (data.value && !isError.value) {
      setCachedTranslation(locale.value, data.value.data);
      translations.value = data.value.data;
    } else if (error.value) {
      // Error while loading. Using cached translations
      captureException(error.value);
      translations.value = getCachedTranslation(locale.value);
    }
  });

  return {
    isLoading: computed(() => isLoading.value),
  };
}
