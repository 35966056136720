<template>
  <AppSPASkeleton />
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import AppSPASkeleton from "@/components/skeletons/AppSPASkeleton.vue";
import router from "@/router";
import { routeNames } from "@/router/route-names";
import { useAuthStore } from "@/store/auth-store";

const authStore = useAuthStore();
const route = useRoute();

onMounted(() => {
  const ssoError = route.query.ssoerror as string | undefined;

  authStore
    .initializeSession()
    .then(() => {
      router.replace({
        name: routeNames.login,
        query: ssoError ? { ssoerror: ssoError } : undefined,
      });
    })
    .catch(() => {
      authStore.logout();
    });
});
</script>

<style scoped lang="scss"></style>
