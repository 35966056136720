import resolveConfig from "tailwindcss/resolveConfig";

import type { IBookingStatus } from "@/api/types/booking-types";
import type { IConnectedAppStatus } from "@/api/types/connected-app-types";
import type { ICustomerSalesStatus } from "@/api/types/customer-types";
import type { IEmailDomainVerificationStatuses } from "@/api/types/email-domain-types";
import type { IEmailStatus } from "@/api/types/email-types";
import type { IInvoiceStatus } from "@/api/types/invoice-types";
import type { ILocationStatus } from "@/api/types/location-types";
import type { IUnitStatusValues } from "@/api/types/unit-types";
import type { IPaymentChargeStatus } from "@/types/payment-types";
import tailwindConfig from "@@/tailwind.config";

export const colors = resolveConfig(tailwindConfig).theme.colors;

export const baseColors = [
  "lightBlue",
  "darkBlue",
  "black",
  "green",
  "orange",
  "red",
  "lightTeal",
  "midTeal",
] as const;
export type BaseColor = (typeof baseColors)[number];

export interface UIColor {
  color: string;
  textColor: string;
}

export interface UIColorSaturated extends UIColor {
  saturatedColor: string;
}

export const colorBase: Record<BaseColor, UIColor> = {
  lightBlue: {
    color: colors.teal[100],
    textColor: colors.teal[600],
  },
  darkBlue: {
    color: colors.blue[100],
    textColor: colors.blue[700],
  },
  black: {
    color: colors.grey[200],
    textColor: colors.grey[800],
  },
  green: {
    color: colors.green[100],
    textColor: colors.green[600],
  },
  orange: {
    color: colors.orange[100],
    textColor: colors.orange[600],
  },
  red: {
    color: colors.red[100],
    textColor: colors.red[600],
  },
  lightTeal: {
    color: colors.teal[300],
    textColor: colors.teal[700],
  },
  midTeal: {
    color: colors.teal[500],
    textColor: colors.grey[50],
  },
};

export const colorGroups = {
  users: {
    default: colorBase.green,
    roles: colorBase.black,
  },
  invoice: {
    default: colorBase.green,
    open: colorBase.darkBlue,
    paid: colorBase.green,
    past_due: colorBase.red,
    retrying: colorBase.orange,
    void: colorBase.black,
    ongoing_dispute: colorBase.orange,
    pending_payment: colorBase.lightBlue,
  } satisfies Record<IInvoiceStatus | "default", UIColor>,
  booking: {
    default: colorBase.green,
    past_due: colorBase.red,
    active: colorBase.green,
    canceled: colorBase.black,
    expired: colorBase.black,
    incomplete: colorBase.orange,
    scheduled: colorBase.lightBlue,
  } satisfies Record<IBookingStatus | "default", UIColor>,
  unit: {
    default: colorBase.green,
    rented: colorBase.darkBlue,
    toCheck: colorBase.orange, // TODO why is this camelCase?
    vacant: colorBase.green,
    blocked: colorBase.black,
    maintenance: colorBase.red,
    reserved: colorBase.lightBlue,
    reserved_adjustment: colorBase.lightTeal,
    reserved_basket: colorBase.midTeal,
  } satisfies Record<IUnitStatusValues | "default", UIColor>,
  paymentMethod: {
    default: colorBase.darkBlue,
  },
  creditNote: {
    default: colorBase.black,
  },
  payment: {
    default: colorBase.black,
    canceled: colorBase.black,
    failed: colorBase.red,
    succeeded: colorBase.green,
    not_paid_yet: colorBase.black,
    pending: colorBase.black,
    disputed: colorBase.orange,
    lost_dispute: colorBase.red,
  } satisfies Record<IPaymentChargeStatus | "default", UIColor>,
  location: {
    default: colorBase.black,
    published: colorBase.green,
    unpublished: colorBase.black,
  } satisfies Record<ILocationStatus | "default", UIColor>,
  customer: {
    default: colorBase.black,
    active: colorBase.green,
    lead: colorBase.lightBlue,
    interested_lead: colorBase.lightBlue,
    former: colorBase.black,
    visitor: colorBase.black,
  } satisfies Record<ICustomerSalesStatus | "default", UIColor>,
  connectedApp: {
    default: colorBase.black,
    connected: colorBase.green,
    disconnected: colorBase.red,
    needs_review: colorBase.red,
    activated: colorBase.green,
  } satisfies Record<IConnectedAppStatus | "default" | "activated", UIColor>,
  email: {
    default: colorBase.green,
    activated: colorBase.green,
    deactivated: colorBase.orange,
  } satisfies Record<IEmailStatus | "default", UIColor>,
  emailDomain: {
    default: colorBase.black,
    verified: colorBase.green,
    action_required: colorBase.orange,
    pending_verification: colorBase.black,
  } satisfies Record<IEmailDomainVerificationStatuses | "default", UIColor>,
  product: {
    default: colorBase.black,
    published: colorBase.green,
    unpublished: colorBase.black,
  }, // doesn't have to satisfy any type because the type is boolean from `is_published`s
} as const;

export type IStatusColorGroup = keyof typeof colorGroups;
export type IModuleStatuses<Group extends IStatusColorGroup> = Exclude<
  keyof (typeof colorGroups)[Group],
  "default"
>;
// export type IModuleStatuses<Group extends IStatusColorGroup> =
//   keyof (typeof colorGroups)[Group];

export const confettiColors = [
  colors.orange["400"],
  colors.red["400"],
  colors.blue["300"],
  colors.blue["700"],
  colors.green["400"],
];

export const checkboxAnimationColors = [...confettiColors, colors.grey["400"]];
