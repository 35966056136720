import dayjs, { Dayjs } from "dayjs";
import { isString } from "lodash-es";

import { DATE_FORMAT_ISO_DATE } from "@/utils/format-utils";

export function isMoveInDateConsideredActive(
  moveInDate?: string | Date | null,
  referenceDate = new Date()
): boolean {
  if (moveInDate != null && dayjs(moveInDate).isValid()) {
    const dayJsDate = dayjs(moveInDate);
    const isToday = dayJsDate.isSame(referenceDate, "day");
    const isInPast = dayJsDate.isBefore(referenceDate);

    return isToday || isInPast;
  }

  return false;
}

export const timePattern = /^\d{1,2}:\d{2}$/;
export const isoDatePattern = /^\d{4}-\d{2}-\d{2}$/;

export function isValidTimeFormat(
  timeString: string | null | undefined
): boolean {
  if (!timeString) {
    return false;
  }

  return timePattern.test(timeString);
}

export function isValidISODateFormat(date: string | null | undefined): boolean {
  if (!date) {
    return false;
  }

  return isoDatePattern.test(date);
}

export function isValidTimeString(
  timeString: string | null | undefined
): boolean {
  if (!isValidTimeFormat(timeString)) {
    return false;
  }

  return isValidTime(getTimeFromString(timeString));
}

export interface ITime {
  hours: number;
  minutes: number;
}

/**
 * Returns an object containing the hours and minutes as numbers.
 * No validation is made.
 * @param timeString
 */
export function getTimeFromString(
  timeString: string | null | undefined
): ITime | undefined {
  if (!isValidTimeFormat(timeString) || !isString(timeString)) {
    return undefined;
  }

  const parts = timeString.split(":").map((p) => +p);
  return {
    hours: parts[0],
    minutes: parts[1],
  };
}

export function isValidTime(time: ITime | undefined): boolean {
  if (!time) {
    return false;
  }

  if (time.hours > 23 || time.hours < 0) {
    return false;
  }

  if (time.minutes > 59 || time.minutes < 0) {
    return false;
  }

  return true;
}

export function getTimeFromDate(
  date: Date | string | number | undefined | null
): ITime | undefined {
  if (date) {
    const d = dayjs(date);

    if (d.isValid()) {
      return {
        hours: d.hour(),
        minutes: d.minute(),
      };
    }
  }

  return undefined;
}

export function applyTime(
  time: ITime | null | undefined,
  applyToDate: Date | string
): Date {
  if (!time) {
    return new Date(applyToDate);
  }

  const date = new Date(applyToDate);
  date.setHours(time.hours);
  date.setMinutes(time.minutes);
  date.setMilliseconds(0);
  return date;
}

export function formatTime(time?: ITime): string {
  if (time) {
    return `${time.hours < 10 ? `0${time.hours}` : time.hours}:${
      time.minutes < 10 ? `0${time.minutes}` : time.minutes
    }`;
  }

  return "";
}

/**
 * returns yyyy-mm-dd for a given date
 * @param date
 */
export function getISODateString(date: Date | string | number) {
  return dayjs(date).format(DATE_FORMAT_ISO_DATE);
}

export function getUnixTimeStamp(
  date: string | Date | null | number | undefined
): number | undefined {
  if (date == null) {
    return undefined;
  }

  const dateObj = new Date(date);
  return dayjs(dateObj).isValid()
    ? Math.floor(dateObj.getTime() / 1000)
    : undefined;
}

/**
 * Returns a Dayjs object with the timezone applied to it.
 * Use `formatTimeZoneDate` to get a timezone-less string from it which can be used for third party libs (like the calendar)
 *
 * See: https://day.js.org/docs/en/plugin/timezone
 *
 * @param date
 * @param timezone
 */
export function toTimeZoneDateTime(
  date: Date | Dayjs | string = new Date(),
  timezone?: string
): string {
  const format = "YYYY.MM.DD HH:mm:ss";
  if (dayjs.isDayjs(date)) {
    return date.tz(timezone).format(format);
  } else {
    return dayjs.utc(date).tz(timezone).format(format);
  }
}
