import type { IInvoiceManualPaymentType } from "@/api/types/invoice-types";
import type { IAllowedPaymentMethod } from "@/api/types/payment-method-types";
import { colors } from "@/config/color-config";

export const paymentMethodsRecord: Record<IAllowedPaymentMethod, string> = {
  card: "Card",
  sepa_debit: "SEPA",
  sofort: "SOFORT",
  klarna: "Klarna",
  giropay: "Giropay",
  ideal: "iDEAL",
  link: "Link",
  bancontact: "Bancontact",
  acss_debit: "ACSS Debit",
  affirm: "Affirm",
  afterpay_clearpay: "Afterpay Clearpay",
  au_bacs_debit: "AU BECS Debit",
  bacs_debit: "Bacs Debit",
  blik: "Blik",
  boleto: "Boleto",
  cashapp: "Cash App",
  customer_balance: "Stripe customer balance",
  eps: "EPS",
  fpx: "FPX",
  grabpay: "GrabPay",
  konbini: "Konbini",
  p24: "P24",
  oxxo: "OXXO",
  paynow: "PayNow",
  paypal: "PayPal",
  pix: "PIX",
  promptpay: "PromptPay",
  revolut_pay: "Revolut Pay",
  us_bank_account: "US Bank Account",
  wechat_pay: "WeChat Pay",
  zip: "Zip",
  twint: "TWINT",
};

export const creditCards = [
  "mastercard",
  "visa",
  "amex",
  "unionpay",
  "diners",
  "discover",
  "cartesbancaires",
  "jcb",
] as const;

export type CreditCard = (typeof creditCards)[number];

export const basePaymentMethods = ["card", "sepa_debit", "bacs_debit"] as const;
export type IBasePaymentMethod = (typeof basePaymentMethods)[number];

export interface PaymentBrand {
  card: Record<CreditCard, PaymentData>;
  other: {
    sepa_debit: PaymentData;
    sofort: PaymentData;
    ideal: PaymentData;
    bancontact: PaymentData;
    bacs_debit: PaymentData;
    customer_balance: PaymentData;
    cash: PaymentData;
    wire_transfer: PaymentData;
    card_onsite: PaymentData;
  };
  sepa_debit: PaymentData;
  custom: PaymentData;
}

export interface PaymentData {
  icon: string | null;
  title: string;
  translation_key: string | null;
}

export const paymentBrands: PaymentBrand = {
  card: {
    mastercard: {
      title: "Card",
      icon: "mastercard",
      translation_key: null,
    },
    visa: {
      title: "Card",
      icon: "visa",
      translation_key: null,
    },
    amex: {
      title: "Card",
      icon: "amex",
      translation_key: null,
    },
    unionpay: {
      title: "Card",
      icon: "unionpay",
      translation_key: null,
    },
    diners: {
      title: "Card",
      icon: "diners",
      translation_key: null,
    },
    discover: {
      title: "Card",
      icon: "discover",
      translation_key: null,
    },
    cartesbancaires: {
      title: "Card",
      icon: "cartesbancaires",
      translation_key: null,
    },
    jcb: {
      title: "Card",
      icon: "jcb",
      translation_key: null,
    },
  },
  other: {
    sepa_debit: {
      title: "SEPA",
      icon: "sepa",
      translation_key: null,
    },
    sofort: {
      title: "Sofort",
      icon: "sofort",
      translation_key: null,
    },
    ideal: {
      title: "iDEAL",
      icon: "ideal",
      translation_key: null,
    },
    bancontact: {
      title: "Bancontact",
      icon: "bancontact",
      translation_key: null,
    },
    bacs_debit: {
      title: "Bacs Direct Debit",
      icon: "bacs_debit",
      translation_key: null,
    },
    customer_balance: {
      title: "Stripe balance",
      icon: "stripe",
      translation_key: null,
    },
    cash: {
      title: "Cash",
      icon: null,
      translation_key: "paymentTypes.cash",
    },
    wire_transfer: {
      title: "Wire Transfer",
      icon: null,
      translation_key: "paymentTypes.wire_transfer",
    },
    card_onsite: {
      title: "Card Onsite",
      icon: null,
      translation_key: "paymentTypes.card_onsite",
    },
  },
  /**
   * @deprecated use `paymentBrands.other.sepa_debit`
   */
  sepa_debit: {
    title: "SEPA",
    icon: "sepa",
    translation_key: null,
  },
  custom: {
    title: "Custom",
    icon: null,
    translation_key: null,
  },
};

export const stripePaymentMethodTypes: (IAllowedPaymentMethod | string)[] = [
  "card",
  "sepa_debit",
  "bacs_debit",
  "sofort",
  "bancontact",
  "ideal",
];
export const manualPaymentTypes: (IInvoiceManualPaymentType | string)[] = [
  "cash",
  "wire_transfer",
  "card_onsite",
  "custom",
];

export const supportedCountries = ["SEPA"];

export const stripeElementStyles = {
  base: {
    color: "#32325D",
    fontWeight: 300,
    fontFamily: "Metropolis, sans-serif",
    fontSize: "16px",
    "::placeholder": {
      color: "rgba(0, 0, 0, 0.42)",
    },
    ":-webkit-autofill": {
      color: "rgba(0, 0, 0, 0.42)",
    },
  },
  invalid: {
    color: colors.app.danger,
    fontWeight: 300,
    fontFamily: "Metropolis, sans-serif",
    fontSize: "16px",
    "::placeholder": {
      color: colors.app.danger,
    },
  },
};

export const stripeElementClasses = {
  focus: "focused",
  empty: "empty",
  invalid: "invalid",
};
