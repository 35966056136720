import type { RouteLocationRaw } from "vue-router";

import { modalSizes } from "@/config/constants";
import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const customerRoutes: RouteMetaConfig[] = [
  {
    path: "/customers",
    name: routeNames.customerOverview,
    component: () => import("./views/CustomerListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "customers.view",
      listFilterName: "customers",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.customerCreate,
          path: "create",
          meta: {
            requiresAuth: true,
            permission: "customers.create",
          },
          component: () => import("./views/CustomerCreateView.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
  {
    path: "/customers/:customerId(\\d+)",
    name: routeNames.customerDetails,
    component: () => import("./views/CustomerDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "customers.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.customerEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "customers.update",
          },
          component: () => import("./views/CustomerEditGeneralView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/details",
          name: routeNames.customerEditDetails,
          meta: {
            requiresAuth: true,
            permission: "customers.update",
          },
          component: () => import("./views/CustomerEditDetailsView.vue"),
        },
        { fullscreen: false, width: modalSizes.md }
      ),
      buildModalRoute(
        {
          path: "edit/running-balance/:locationId(\\d+)",
          name: routeNames.customerEditRunningBalance,
          meta: {
            requiresAuth: true,
            permission: "customers.update",
          },
          component: () => import("./views/CustomerEditRunningBalanceView.vue"),
        },
        { fullscreen: false, width: modalSizes.lg }
      ),
      buildModalRoute(
        {
          path: "balance-transactions/:locationId(\\d+)",
          name: routeNames.customerBalanceTransactions,
          meta: {
            requiresAuth: true,
            permission: "customers.view",
          },
          component: () =>
            import("./views/CustomerBalanceTransactionsView.vue"),
        },
        { fullscreen: false, width: modalSizes.xl }
      ),
    ],
  },
];

export const customerRoutesFactory = {
  customerDetails(customerId: number | string): RouteLocationRaw {
    return {
      name: routeNames.customerDetails,
      params: {
        customerId,
      },
    };
  },
  customerEditGeneral(customerId: number | string): RouteLocationRaw {
    return {
      name: routeNames.customerEditGeneral,
      params: {
        customerId,
      },
    };
  },
  customerEditDetails(customerId: number | string): RouteLocationRaw {
    return {
      name: routeNames.customerEditDetails,
      params: {
        customerId,
      },
    };
  },
  customerEditRunningBalance(
    customerId: number | string,
    locationId: number
  ): RouteLocationRaw {
    return {
      name: routeNames.customerEditRunningBalance,
      params: {
        customerId,
        locationId,
      },
    };
  },
  customerBalanceTransactions(
    customerId: number | string,
    locationId: number
  ): RouteLocationRaw {
    return {
      name: routeNames.customerBalanceTransactions,
      params: {
        customerId,
        locationId,
      },
    };
  },
};
