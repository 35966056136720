import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesConnectedApps: RouteMetaConfig[] = [
  {
    path: "/connected-apps",
    name: routeNames.connectedApps,
    component: () => import("./views/ConnectedAppsListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "connected_app_connections.view",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.connectedAppsSensorberg,
          path: "sensorberg/:id",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          props: true,
          component: () => import("./views/ConnectedAppsSensorbergView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsSCSolutions,
          path: "sc-solutions/:id",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          props: true,
          component: () => import("./views/ConnectedAppsScSolutionView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsNoke,
          path: "noke/:id",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          props: true,
          component: () => import("./views/ConnectedAppsNokeView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsSedisto,
          path: "sedisto/:id",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          component: () => import("./views/ConnectedAppsSedistoView.vue"),
          props: true,
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsSignable,
          path: "signable/:id",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          component: () => import("./views/ConnectedAppsSignableView.vue"),
          props: true,
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsSignableHowToSetup,
          path: "signable-how-to-setup",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          component: () =>
            import("./views/ConnectedAppsSignableHowToSetup.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsStripeIdCheck,
          path: "id-check/:id",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          props: true,
          component: () => import("./views/ConnectedAppsStripeIdCheckView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsOpenTech,
          path: "open-tech/:id",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          props: true,
          component: () => import("./views/ConnectedAppsOpenTechView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsPaxtonNet2,
          path: "paxton-net2/:id",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          props: true,
          component: () => import("./views/ConnectedAppsPaxtonNet2View.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsBearBox,
          path: "bear-box/:id",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          props: true,
          component: () => import("./views/ConnectedAppsBearBoxView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsTapKey,
          path: "tap-key/:id",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          props: true,
          component: () => import("./views/ConnectedAppsTapKeyView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsAzureAd,
          path: "sso-azure-ad/:id",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          props: true,
          component: () => import("./views/ConnectedAppsAzureAdView.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];
