<template>
  <div class="mt-5 flex w-full justify-between">
    <div v-if="$slots['aside']" class="justify-self-start">
      <slot name="aside" />
    </div>
    <div class="flex w-full justify-end gap-4" data-test-id="modal-body">
      <UIButton
        variant="black-clear"
        :disabled="disabled || loading"
        type="button"
        :data-test-id="dataTestIdCancel"
        @click="emit('click:cancel', $event)"
        >{{ cancelText || t("generic.cancel") }}
      </UIButton>

      <UIButton
        :disabled="disabled || disabledSubmit"
        :loading="loading"
        :form="form"
        :type="type"
        :variant="variant"
        depressed
        :data-test-id="dataTestIdSubmit"
        @click="emit('click:submit', $event)"
        >{{ submitText || t("generic.save") }}
      </UIButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import type { TranslateResult } from "vue-i18n";

import { useTranslate } from "@/composables/use-translate";
import UIButton from "@/modules/ui/buttons/components/UIButton.vue";
import type { ITableRowConfirmData } from "@/types/action-menu-types";

const { t } = useTranslate();

interface Props {
  color?: ITableRowConfirmData["color"];
  disabled?: boolean;
  disabledSubmit?: boolean;
  loading?: boolean;
  form?: string;
  cancelText?: string | TranslateResult;
  submitText?: string | TranslateResult;
  type?: "submit" | "button";
  noMargin?: boolean;
  dataTestIdCancel?: string;
  dataTestIdSubmit?: string;
}

const props = withDefaults(defineProps<Props>(), {
  color: "primary",
  form: undefined,
  cancelText: undefined,
  disabled: false,
  loading: false,
  submitText: undefined,
  disabledSubmit: undefined,
  type: "submit",
  noMargin: false,
  dataTestIdCancel: "close-button",
  dataTestIdSubmit: "submit-button",
});

const emit = defineEmits<{
  "click:cancel": [Event];
  "click:submit": [Event];
}>();

const variant = computed(() => {
  switch (props.color) {
    case "error":
      return "danger-full";
    case "warning":
      return "warning-full";
    case "primary":
    default:
      return "primary";
  }
});
</script>
