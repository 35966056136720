import type { AxiosError, AxiosResponse } from "axios";
import { isArray, isString, toNumber } from "lodash-es";
import type { TranslateResult } from "vue-i18n";

import type {
  IApiError,
  IAPIErrorDetailsMap,
  IAPIErrorDetailsMapWithLanguage,
  IAPIErrorNestedDetailsMap,
} from "@/api/types/api-error-types";
import { i18n } from "@/plugins/i18n-plugin";
import { extractIndices, extractNumber } from "@/utils/build-string-utils";

export function isAPIError(input: unknown): input is AxiosError<IApiError> {
  if (!input) {
    return false;
  }

  const axiosResponse = input as AxiosError<IApiError>;
  return axiosResponse.response?.data?.error?.details != null;
}

export function getApiErrorMessages(error: unknown) {
  if (error) {
    if (isAPIError(error)) {
      return error.response?.data.error.details;
    } else if (error instanceof Error) {
      return [error.message];
    }

    return ["validation.unknown_error"];
  }

  return undefined;
}

export function getTranslatedApiErrorMessages(
  error: unknown
): TranslateResult[] {
  const errors = getApiErrorMessages(error);

  if (errors && errors.length) {
    return errors.map((e) => {
      if (isString(e)) {
        return i18n.global.t(e);
      } else {
        return i18n.global.t(e.code, { field: e.field });
      }
    });
  }

  return ["validation.unknown_error"] as TranslateResult[];
}

export function getApiErrorArray(
  error: AxiosError<IApiError>
): IAPIErrorDetailsMap {
  const errorDetails = error.response?.data?.error?.details;
  const dummy: IAPIErrorDetailsMap = {};

  errorDetails?.forEach((detail) => {
    dummy[extractNumber(detail.field)] = { ...detail };
  });

  return dummy;
}

/**
 * Returns a map of error details grouped by index and language
 * Example field: 'data.0.name.SV' or 'data.1.name.RU'
 */
export function getApiErrorLanguageArray(
  error: AxiosError<IApiError>
): IAPIErrorDetailsMapWithLanguage {
  const errorDetails = error.response?.data?.error?.details;
  const result: IAPIErrorDetailsMapWithLanguage = {};

  errorDetails?.forEach((detail) => {
    const field = detail.field;
    const matches = field.match(/^data\.(\d+)\..*?\.([A-Z]{2})$/);

    if (matches) {
      const [, indexStr, language] = matches;
      const index = Number(indexStr);

      if (!result[index]) {
        result[index] = {};
      }
      if (!result[index][language]) {
        result[index][language] = {
          code: detail.code,
          message: detail.message,
          field: detail.field,
        };
      }
    }
  });

  return result;
}

/**
 * Extracts the error code without the item index
 * Example input error code: '0.0.item_distinct'
 * Returns: '0.item_distinct'
 */
export function extractErrorField(str: string) {
  const regex = /^\d+\.(.*)$/;
  const match = str.match(regex);
  return match ? match[1] : "";
}

/**
 * Returns a map of error details grouped by item and field
 * Use this if the error details are nested and you need to access them by item and field
 * Example error code: '0.0.item_distinct'
 */
export function getNestedApiErrorMap(
  error: AxiosError<IApiError>
): IAPIErrorNestedDetailsMap {
  const errorDetails = error.response?.data?.error?.details;
  const errorMap: IAPIErrorNestedDetailsMap = {};

  errorDetails?.forEach((detail) => {
    const [itemIndex, fieldIndex] = extractIndices(detail.field);

    if (!errorMap[itemIndex]) errorMap[itemIndex] = {};
    errorMap[itemIndex][fieldIndex] = {
      ...detail,
      field: extractErrorField(detail.field),
    };
  });

  return errorMap;
}

/**
 * Throws an error if the given value is null or undefined, returns the value otherwise
 */
export function getValueOrThrow<T>(
  value: T | undefined,
  propName?: string
): NonNullable<T> {
  if (value != null) {
    return value as NonNullable<T>;
  }

  throw new Error(`${propName || "value"} is undefined or null`);
}

export function getContainingValueOrThrow<T>(
  value: T | undefined,
  containedIn: unknown[],
  propName?: string
): T {
  const checkedValue = getValueOrThrow(value, propName);

  if (containedIn.includes(checkedValue)) {
    return checkedValue;
  }

  throw new Error(
    `${propName || "value"} is not included in given values: ${JSON.stringify(
      containedIn
    )}`
  );
}

export function getArrayOrThrow<T>(value: T | undefined, propName?: string): T {
  const nonNullValue = getValueOrThrow(value, propName);

  if (!isArray(nonNullValue)) {
    throw new Error(`${propName || "value"} is not an array`);
  }

  return nonNullValue;
}

export function getNonEmptyArrayOrThrow<T>(
  value: T | undefined,
  propName?: string
): T {
  const nonNullValue = getArrayOrThrow(value, propName);

  if (isArray(nonNullValue) && nonNullValue.length <= 0) {
    throw new Error(`${propName || "value"} is empty`);
  }

  return nonNullValue;
}

/**
 * Throws an error if the given value is null, undefined or not a number, returns a number otherwise
 */
export function getNumberOrThrow<T>(
  value: T | undefined,
  propName?: string
): number {
  const val = getValueOrThrow(value, propName);
  // TODO: add correct formatting i18n
  const numValue = toNumber(`${val}`.replace(",", "."));

  if (isNaN(numValue) || `${value}` === "") {
    throw new Error(`${propName || "value"} is not a number: ${value}`);
  }

  return numValue;
}

export function getNumberOrFallback<T, TFallback>(
  value: T | undefined | null,
  fallback: TFallback
): number | TFallback {
  try {
    return getNumberOrThrow(value);
  } catch (e) {
    return fallback;
  }
}

export function getStringOrThrow<T extends string>(
  value: T | undefined | null,
  propName?: string
): T {
  const val = getValueOrThrow(value, propName);

  if (!isString(val)) {
    throw new Error(`${propName || "value"} is not a string: ${value}`);
  }

  return val;
}

export function getMinNumberOrThrow<T>(
  value: T | undefined,
  min: number,
  propName?: string
): number {
  const val = getNumberOrThrow(value, propName);
  if (val < min) {
    throw new Error(`${propName || "value"} is lower than ${min}: ${val}`);
  }

  return val;
}

/**
 * It validates if the given data is an API error.
 */
export function isApiError(e: unknown): e is IApiError {
  const error = e as IApiError;

  return (
    error?.error?.code != null &&
    error?.error?.message != null &&
    error?.error?.details != null
  );
}

/**
 * Type guard that checks if the response from the backend is an API error.
 * That means it checks weather the response has the correct structure.
 */
export function isApiResponseError(
  e: unknown
): e is { response: AxiosResponse<IApiError> } {
  const error = e as AxiosError;
  return isApiError(error?.response?.data);
}

export function getApiError(
  error: Error | IApiError | undefined | unknown
): IApiError | undefined {
  if (isApiError(error)) {
    return error;
  }

  if (isApiResponseError(error)) {
    return error.response?.data;
  }

  return undefined;
}
