<template>
  <AppSPASkeleton />
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import AppSPASkeleton from "@/components/skeletons/AppSPASkeleton.vue";
import { useAuthStore } from "@/store/auth-store";

const authStore = useAuthStore();
const route = useRoute();

onMounted(() => {
  const ssoError = route.query.ssoerror as string | undefined;
  authStore.login(ssoError);
});
</script>

<style scoped lang="scss"></style>
